.browser {
  font-size: 36px;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 699px) {
    font-size: 48px;
  }

  a {
    color: inherit;
  }

  .layer;

  @logoWidth:235px;
  @logoHeight:530px;

  .lollipop{
    position: absolute;
    background:url('../../img/lollipop_overlay_fpo.png') center center no-repeat;
    width:@logoWidth;
    height:@logoHeight;
    background-size:@logoWidth @logoHeight;
    top:50%;
    left:50%;
    cursor: pointer;
    margin-top:-(@logoHeight/2) - 40px;
    margin-left:-@logoWidth/2;
  }
}