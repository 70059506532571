#text-input {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #161818;
    border: none;
    border-top: 2px solid black;
    color: #888a8a;
    padding-left: 30px;
    font-size: 16px;
    height: 56px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}