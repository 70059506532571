
@chatWidth:375px;
@chatHeight:(@appWidth - @chatWidth) / (@appWidth / @appHeight);
@chatTop:(@appHeight - @chatHeight) / 2;

#chat{
	position: absolute;
	right:0px;
	top:@chatTop;
	width:@chatWidth;
	height:@chatHeight;
	background: #0e0f0f;
	.ProximaNovaRegular;
	color:#888a8a;
	overflow: hidden;

	&.hide{
		display: none;
	}
	
	&-container {
		overflow: auto;
		height: 800px;
		padding-top:20px;
	}

	#chat-message {
		padding: 10px 30px;
		font-size: 16px;
		line-height:1.2;
		#user {
			font-weight: 800;
			color: #cfcfcf;
		}
	}

	.message-enter:last-child {
		opacity: 0;
		transform: scale(0.9);
	}
	.message-enter-active:last-child {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 300ms, transform 300ms;
	}

}





