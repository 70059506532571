@logoWidth:235px;
@logoHeight:530px;
@logoWidthOrig:350px;
@logoHeightOrig:920px;

#landing-page{
	.layer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	a{
		outline: none;
	}

	#powered-by-imposium{
		position:fixed;
		bottom:5px;
		right:50%;
		margin-right:-40px;
		width:80px;

		@media @laptop{
			width:150px;
			right:5px;
			margin-right:0px;
		}
	}

	#landing-background{
		.layer;
	}

	#new{
		.layer;
	}

	#overlay{
		.layer;
		pointer-events:none;

		h1{
			color:#0893f5;
			opacity:0.7;
			font-size:11px;
			text-transform:uppercase;
			text-align: center;
			margin-top:32px;
			.Knockout;

			@media @laptop{
				font-size: 21px;
				margin-top: 65px;
			}

			@media @desktop{
				font-size:28px;
				margin-top:135px;
			}
		}
	}

	#original{
		.layer;
		background:url('../../img/bg_original.jpg') center center no-repeat;
		background-size:cover;
	}

	.scaled{
		position:absolute;
		top:0px;
		left:0px;
		width:1920px;
		height:1080px;
	}

	.lollipop{
		position: absolute;
		background:url('../../img/lollipop_overlay_fpo.png') center center no-repeat;
		width:@logoWidth;
		height:@logoHeight;
		background-size:@logoWidth @logoHeight;
		top:50%;
		left:50%;
		cursor: pointer;
		margin-top:-(@logoHeight/2) - 40px;
		margin-left:-@logoWidth/2;

		// &:hover{
		// 	filter: hue-rotate(-209deg) saturate(130%) contrast(140%) brightness(0.7);
		// }
	}

	.lollipop-original{
		position: absolute;
		background:url('../../img/lollipop_overlay_no_tape.png') center center no-repeat;
		width:@logoWidthOrig;
		height:@logoHeightOrig;
		background-size:@logoWidthOrig @logoHeightOrig;
		top:50%;
		left:50%;
		cursor: pointer;
		margin-top:-(@logoHeightOrig/2) - 40px;
		margin-left:-@logoWidthOrig/2;
	}

	.dare{
		font-size:150px;
		color:#0893f5;
		margin-top: 485px;
		opacity:0.35;
		.Knockout;
	}

	.date{
		color:#0893f5;
		opacity:0.7;
		.Knockout;
		text-align: center;
		width:100%;
		margin-top:-100px;
		font-size:34px;
		display: none;

		@media @laptop{
			display:block;
		}
	}
}

#landing-browser {
	width: 100%;
	max-width: 1280px;
	font-size: 240%;
	position: absolute;
	text-align: center;
	letter-spacing:.3px;	
	margin-top:550px;
	
	a {
		color: inherit;
	}
}
.transition-enter {
  opacity: 0.01;
}

.transition-enter.transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-exit {
  opacity: 1;
}

.transition-exit.transition-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.transition-appear {
  opacity: 0.01;
}

.transition-appear.transition-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}