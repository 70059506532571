@import "reset.less";
@import "mixins.less";
@import "colors.less";
@import "fonts.less";
@import "components/**/*.less";

@appWidth:1920px;
@appHeight:1080px;
@camWidth:640px;
@camHeight:360px;

body{
	background:black;
	overflow: hidden;
}

#app{
	background: black;
	color:@textDefault;
	overflow: hidden;
	.FuturaMedium;
}

#controls{
	position:absolute;
	top:0px;
	right:0px;
}

#scaled{
	
}

h1, h2, h3{
	text-transform:uppercase;
	letter-spacing:1.2px;
	white-space: pre-line;
}

h1{
	color:@textBright;
	font-size:26px;
	letter-spacing:2px;
}

h2{
	font-size:20px;
	letter-spacing:1px;
	line-height:1.2;
	text-align:center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
	-webkit-box-shadow: 0 0 0 60px #fafafa inset !important;
	background-color: #fafafa !important;
	background-clip: content-box !important;
}
.btn-plus{
	display: inline-block;
	position: relative;
	width:150px;
	height:150px;
	margin:75px;
    cursor: pointer;

    &:hover{
		filter:hue-rotate(-340deg) saturate(200%) contrast(110%) brightness(1.2);
    }

    &.disabled{
    	pointer-events:none;
    	filter:saturate(0%);
    	opacity: 0.2;
    }

	.vid-plus{
		pointer-events:none;
		position:absolute;
		top:-25px;
		left:-75px;
		object-fit: fill;
		width:290px;
		height:230px;
		margin-bottom:50px
	}
}
.join-plus{
	display: inline-block;
	cursor: pointer;
	position:absolute;
	top:50%;
	left:50%;
	margin:-150px
}
.btn-dash{
	display: inline-block;
	margin:50px;
	width:150px;
	height:150px;
	cursor: pointer;
	position: relative;
	.VT323;

	&:hover{

		.vid{
			filter:hue-rotate(-340deg) saturate(200%) contrast(110%) brightness(1.2);
		}
	}

	&.disabled{
		pointer-events:none;
		filter:saturate(0%);
		opacity: 0.2;
	}

	.vid{
		pointer-events:none;
		position:absolute;
		top:-50px;
		left:-50px;
		width:250px;
		height:250px;
	}

	.btn-text {
	    position: absolute;
	    top: 62px;
	    width: 100%;
	    text-align: center;
	    font-size: 25px;
	    color: #000;
	    filter: blur(.5px);
	    font-family: FuturaStd-Medium,sans-serif;
	    letter-spacing: 2px;

	}

	&.wide{
		width:300px;

		.vid{
			width:500px;
			left:-100px;
		}

		.btn-text{
			letter-spacing: 1px;
			font-size:21px;
			top:63px;
		}
	}
}

.grunge{
	.layer;
	pointer-events: none;

	@grungeOffset:-600px;
	@grungeSize:850px;

	.grunge-top, .grunge-bottom{
		position:absolute;
		width:@grungeSize;
		height:@grungeSize;
		left:50%;
		margin-left:-@grungeSize/2;
		background:url('../img/grunge_texture.jpg') center center no-repeat;
		background-size:@grungeSize @grungeSize;
		opacity: 0.2;
	}

	.grunge-top{
		top:@grungeOffset - 100px;
	}

	.grunge-bottom{
		bottom:@grungeOffset + 50px;
	}
}