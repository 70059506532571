
@infoWidth:600px;
@inputWidth:600px;
@inputHeight:300px;

#info-page{
	.layer;

	a{
		color:white;
		text-decoration: underline;
	}

	#info{
		text-align: center;
		position: absolute;
		width:@infoWidth;
		left:(@appWidth - @infoWidth) / 2;
		padding-top:200px;

		&.mobile{
			width:100%;
			height:100%;
		}

		.checkbox {
			margin-left: 100px;
		}
	}
	
	.input-wrapper{

		width:@inputWidth;
		height:@inputHeight;
		position: relative;

		
		.video-dash{
			.layer;
		}

		input{
			.FuturaMedium;
			font-size:26px;
			position:absolute;
			appearance:none;
			background: none;
			border:none;
			color:black;
			text-align: center;
			width:355px;
			height:40px;
			top:137px;
			left:120px;
			filter:blur(0.5px);
			letter-spacing:3px;
			text-transform: uppercase;
			&::placeholder{
				color:black;
			}
		}
	}

	.info-terms {
		line-height: 1.5;
		letter-spacing:0.5px;
		font-size: 13px;
		text-transform: none;
		margin-bottom: 35px;
		
		a:link, a:visited {
			color: inherit;
		}
	}

	a:link, a:visited {
		text-decoration: none;
	}
}