
@font-face {
    font-family: 'FuturaStd-Medium';
    src: url('https://d2hua6cusslj16.cloudfront.net/fonts/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),  url('https://d2hua6cusslj16.cloudfront.net/fonts/FuturaStd-Medium.woff') format('woff'), url('https://d2hua6cusslj16.cloudfront.net/fonts/FuturaStd-Medium.ttf')  format('truetype'), url('https://d2hua6cusslj16.cloudfront.net/fonts/FuturaStd-Medium.svg#VT323-Regular') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

.FuturaMedium{
    font-family:'FuturaStd-Medium', sans-serif;
}


@font-face {
    font-family: 'ProximaNova-Regular';
    src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf')  format('truetype'), url('../fonts/ProximaNova-Regular.svg#VT323-Regular') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

.ProximaNovaRegular{
    font-family:'ProximaNova-Regular', sans-serif;
}

@font-face {
  font-family: 'VT323-Regular';
  src: url('https://d2hua6cusslj16.cloudfront.net/fonts/VT323-Regular.eot?#iefix') format('embedded-opentype'),  url('https://d2hua6cusslj16.cloudfront.net/fonts/VT323-Regular.woff') format('woff'), url('https://d2hua6cusslj16.cloudfront.net/fonts/VT323-Regular.ttf')  format('truetype'), url('https://d2hua6cusslj16.cloudfront.net/fonts/VT323-Regular.svg#VT323-Regular') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

.VT323{
    font-family:'VT323-Regular', monospace;
}

@font-face {
    font-family: 'Knockout-HTF54-Sumo';
    src: url('../fonts/Knockout-HTF54-Sumo.eot?#iefix') format('embedded-opentype'),  url('../fonts/Knockout-HTF54-Sumo.woff') format('woff'), url('../fonts/Knockout-HTF54-Sumo.svg#Knockout-HTF54-Sumo') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
  }
  
  .Knockout{
      font-family:'Knockout-HTF54-Sumo', monospace;
  }

  @font-face {
    font-family: 'nerdproof-webfont';
    src: url('../fonts/nerdproof-webfont.eot?#iefix') format('embedded-opentype'),  url('../fonts/nerdproof-webfont.woff') format('truetype'),  url('../fonts/nerdproof-webfont.ttf') format('woff'), url('../fonts/nerdproof-webfont.svg#nerdproof-webfont') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
  }
  
  .Nerfproof{
      font-family:'nerdproof-webfont', monospace;
      text-transform: uppercase;
  }


  @font-face {
    font-family: 'knockout-htf30-juniorwelterwt-webfont.eot';
    src: url('../fonts/knockout-htf30-juniorwelterwt-webfont.eot?#iefix') format('embedded-opentype'),  url('../fonts/knockout-htf30-juniorwelterwt-webfont.woff') format('truetype'),  url('../fonts/knockout-htf30-juniorwelterwt-webfont.ttf') format('woff'), url('../fonts/knockout-htf30-juniorwelterwt-webfont.svg#knockout-htf30-juniorwelterwt-webfont.eot') format('svg');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
  }
  
  .KnockoutJr{
      font-family:'knockout-htf30-juniorwelterwt-webfont.eot', monospace;
  }