@infoWidth:600px;
@inputWidth:600px;
@inputHeight:300px;

#end-page{
	.layer;

	.enhanced{
		margin-bottom:30px;
        margin-top:30px;

        @media @laptop {
            white-space: nowrap;
        }
	}

	.end-title{
		margin-top:40px;
		box-sizing:border-box;
        text-align: center;
        white-space: nowrap;
	    font-size: 15px;
	}

	.btn-dash{
		margin-top: 50px;
	}

	#ui-wrapper{
		text-align: center;
		position: absolute;
		width:@infoWidth;
        left:(@appWidth - @infoWidth) / 2;
        padding-top: 100px;

		&.mobile{
			width:100%;
			height:100%;

		}
    }
    
    @offset:160px;

    .input-wrapper{

        width:@inputWidth;
        height:@inputHeight - @offset;
        position: relative;
        overflow: hidden;

        .vid-dash{
            position: absolute;
            top:-@offset/2;
            left:0px;
            width:@inputWidth;
            height:@inputHeight;
        }

        input{
            font-family: FuturaStd-Medium,sans-serif;
            letter-spacing:2px;
            font-size:25px;
            position:absolute;
            appearance:none;
            background: none;
            border:none;
            color:black;
            text-align: center;
            width:355px;
            height:40px;
            top:54px;
            left:120px;
            filter:blur(0.5px);
            &::placeholder{
                color:black;
            }
        }
    }

    .img-wrapper{
		cursor: pointer;
		border:1px solid white;
		width:320px;
		height:180px;
		margin:0px auto 0px auto;
		
		img{
			width:100%;
			height:100%;
		}
	}

    .upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}