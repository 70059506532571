
@inputHeight:300px;

#payment-page-original{
	.layer;

	#payment{
		text-align: center;
		position: absolute;
		width:100%;
		left:0;
		top:0;
		padding-top:20px;

		&.mobile{
			width:100%;
			height:100%;

		}

		iframe{
			border:2px solid #666;
		}
	}

	.below-video{
		margin-top:65px;
		width:960px;
		display: inline-block;

        p{
            margin:0 auto;
            max-width:650px;
            font-family:Arial, helvetica, sans-serif;
            font-weight:normal;
            font-size:17px;
            color:#888682;
            text-align:justify;

            .strong{
                color:rgb(37, 37, 37);
            }
        }
	}

    .btn-wrapper{
        margin-top:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
}

.btn-original{
    cursor:pointer;
    margin-left:20px;
    margin-right:20px;
    width:325px;
    height:96px;
    position:relative;

    .bg{
        .layer;
    }

    .copy{
        filter:saturate(0) brightness(2);
        transition:filter 200ms @easeOutQuad;
        .layer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.hover{
        .copy{
            filter:saturate(1);
        }
    }
}