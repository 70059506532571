#video-page-original{
    .layer;

    #player-video{
        opacity:0;

        &.loaded{
            opacity:1;
        }

        .layer;
        object-fit:cover;
        object-position: center center;
        background:black;
    }

    .btn-play{
        position:absolute;
        width:60px;
        height:60px;
        display: flex;
        justify-content: center;
        align-items: center;
        top:50%;
        left:50%;
        margin-top:-30px;
        margin-left:-30px;
        background: rgba(255,255,255,0.1);
        border-radius:30px;
        box-sizing: border-box;
        cursor:pointer;

        &:hover{
            background: rgba(255,255,255,0.3);
        }
    }
}

.loader-original{
    .layer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{
        font-size:24px;
    }
}