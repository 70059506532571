.cookie {
    position: absolute;
    bottom: 0;
    background-color: darkslategrey;
    color: white;
    width: 100%;
    height: 50px;
    font-size: small;
    display: flex;
    justify-content: center;
    align-items: center;

    .agree {
        cursor: pointer;
    }

    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        padding: 5px;
        text-align: center;
        cursor: pointer;
    }
}