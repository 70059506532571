#soul-collection-cta{
    .layer;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding:20px;

    #tint{
        cursor: pointer;
        .layer;
        backdrop-filter: blur(5px);
        background-color: rgba(0,0,0,0.7);
    }

    a{
        text-decoration: none;
    }

    #soul-collection-popup{
        width: 100%;
        max-width:600px;
        background-color: black;
        padding:10px;
        position: relative;
        display: flex;
        color:white;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border:1px solid white;
        text-decoration: none;

        img{
            width:100%;
            max-width:460px;
            height:auto;
            margin-bottom:10px;
        }
        
        #btn-close-popup{
            cursor: pointer;
            align-self: flex-end;
            font-size:20px;
        }

        p{
            text-align: center;
            width:100%;
            max-width:230px;
        }

        a{
            text-decoration: underline;
            color:white;
        }
    }

    @media @laptop{

        #soul-collection-popup{

            padding:20px;

            #btn-close-popup{
                align-self: flex-end;
                font-size:24px;
            }

            p{
                font-size:20px;
                max-width:300px;
            }
        }
    }
}