#code-overlay {
  pointer-events: none;
  position: absolute;
  padding: 40px;
  font-size: 18px;
  top: 0;
  left: 0;
  z-index: 1000000;
  color: #bbdefb;
  opacity: 1;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(0.7px);
  filter: blur(0.6px);
  font-family:'Courier', monospace;
  text-shadow: 1px 0px 5px #2196f3, -1px 0px 2px #42a5f5 !important
}