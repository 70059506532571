
.checkbox{
	position: relative;
	height:36px;
	width:300px;	
	display: inline-block;

	.box{
		position: absolute;
		top:0px;
		left:20px;
		width:40px;
		height:40px;
		font-size: 11px;
    	text-align: center;
    	line-height: 24px;
    	margin-top:-8px;
    	cursor:pointer;

    	// &:hover{
    	// 	video{
    	// 		filter:hue-rotate(-89deg) saturate(180%) contrast(120%);
    	// 	}
    	// }

    	video{
    		pointer-events: none;
    		position:absolute;
    		top:-20px;
    		left:-20px;
    		width:80px;
    		height:80px;
    	}

    	.icon-wrapper{
    		.layer;
    		font-size: 15px;
    		line-height: 40px;
    		filter:blur(1px);
    	}
	}

	.cta{
		letter-spacing:1.2px;
		font-size:18px;
		text-transform:uppercase;
		text-align: left;
// 		margin-top: 2px;
		margin-left: 30px;
		left:30px;
		top:11px;
		width:100px;
		color:white;
		position:absolute;


		a{
			color:@textDefault;
			text-decoration: underline;
			cursor: pointer;
			
			&:hover{
				color:white;
			}
		}
	}

	&.selected{
		.box{
			background: #e6e6e6;
			color:black;

			// &:hover{
			// 	video{
			// 		filter:hue-rotate(-199deg) saturate(180%) contrast(120%);
			// 	}
			// }
		}
	}
}