#end-page-original{
    .layer;
    background:url('../../img/lollipop_bg_red.jpg') center center no-repeat;
    background-size:cover;
    display: flex;
    justify-content: center;
    align-items: center;

    #ui{
        width:447px;
        height:657px;
        position: relative;
        
        #name{
            position: absolute;
            top:510px;
            left:150px;
            width:138px;
            height:56px;
            color:#180700;
            opacity:0.7;
            .Nerfproof;
            font-size: 55px;
            text-align: center;
            line-height: 54px;
            filter: blur(0.5px);
        }

        #next{
            .KnockoutJr;
            position: absolute;
            bottom: 10px;
            left: 0;
            text-align: center;
            width: 100%;
            opacity: .4;
            text-transform: uppercase;
            color: #ab1915;
            font-size: 20px;
        }
        #home{
            .KnockoutJr;
            position: absolute;
            bottom: -20px;
            left: 210px;
            text-align: center;
            width: 100px;
            opacity: 1;
            text-transform: uppercase;
            color: #b51a19;
            font-size: 20px;
            a {
                text-decoration:none;
                color: #b51a19;
             }
            &.active{
                color:white;
            }
            &.hover, &.down, &.visited,&.link{
                color:white;
            }
        }
        
        #replay{
            .KnockoutJr;
            position: absolute;
            bottom: -20px;
            left: 130px;
            text-align: center;
            width: 100px;
            opacity: 1;
            text-transform: uppercase;
            color: #b51a19;
            font-size: 20px;
            a {
                text-decoration:none;
                color: #b51a19;
             }
            &.active{
                color:white;
            }
            &.hover, &.down, &.visited,&.link{
                color:white;
            }
        }
        
    }
}