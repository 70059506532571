#landing{

	#player-wrapper{
		display: none;
		position: absolute;

		&.show{
			display: block;
		}

		#bg-player{
			.layer;
		}

		#stream-wrapper{
			position: absolute;
			width:50%;
			height:50%;
			top:50%;
			left:50%;
			overflow: hidden;

			.icon{
				position:absolute;
				top:10px;
				left:10px;

				&.green{
					color:green;
				}

				&.red{
					color:red;
				}

				&.purple{
					color:purple;
					transform-origin: center center;
					animation: rotating 2s linear infinite;
				}
			}
		}

		#stream-player{
			.layer;
		}

		#encoded-player{
			.layer;
			display: none;

			&.show{
				display: block;
			}
		}
	}	
}

@keyframes rotating {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}