
#body-pix-renderer{

	width:@camWidth;
	height:@camHeight;

	.layer;
	top:-100%;

	#composite-canvas{
		.layer;
	}
}