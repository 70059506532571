/***************************************************************************************************************/
                  /* ----------- GLOBAL ------------*/
/***************************************************************************************************************/

.layer {
    position:absolute;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
}

.ccnr{
  background-position:center center;
  background-repeat:no-repeat;
}
.smartFill {
    width:100%;
    max-height: 100%;
}

.placeholder(@color) {
    ::-webkit-input-placeholder {
        transition: color 180ms;
        color: @color;
    }
    ::-moz-placeholder {
        transition: color 180ms;
        color: @color;
    }
    :-ms-input-placeholder {
        transition: color 180ms;
        color: @color;
    }
    :hover::-webkit-input-placeholder {
        color: lighten(@color, 20%);
    }
    :hover::-moz-placeholder {
        color: lighten(@color, 20%);
    }
    :hover:-ms-input-placeholder {
        color: lighten(@color, 20%);
    }
}

/***************************************************************************************************************/
                  /* ----------- MIXINS ------------*/
/***************************************************************************************************************/

.glitch(@highlight1, @highlight2) {
  @offset1: 2px;
  @offset2: -2px;
  
  &::before,
  &::after  {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  &::before {
    left: @offset1;
    text-shadow: -2px 0 @highlight1;
    clip: rect(24px, 550px, 90px, 0);
    animation: glitch-anim-2 1.5s infinite linear alternate-reverse;
  }
  
  &::after {
    left: @offset2;
    text-shadow: -2px 0 @highlight2;
    clip: rect(85px, 550px, 140px, 0);
    animation: glitch-anim 2s infinite linear alternate-reverse;
  }
}

.glitch-frames (@n: 20, @index: 0) when (@index <= @n) {
  @keyframeSel: percentage(@index/@n);
  @rand1: unit(round(`Math.random()*150`),px);
  @rand2: unit(round(`Math.random()*150`), px);
  @{keyframeSel} {
    clip: rect(@rand1, 9999px, @rand2, 0);
  }
  .glitch-frames(@n, (@index + 1));
}

@keyframes glitch-anim {
  //.glitch-frames(24);
}

@keyframes glitch-anim-2 {
  //.glitch-frames(30,2);
}

@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

.retina(@path, @w: auto, @h: auto, @size:false) {

    background-image: url(@path);
    background-size: @w @h;

    & when (@size = true){
        width:@w;
        height:@h;
    }
}

.scrollbars(@size, @foreground-color, @background-color: mix(@foreground-color, white,  50%)){
  ::-webkit-scrollbar {
      width:  @size;
      height: @size;
  }

  ::-webkit-scrollbar-thumb {
      background: @background-color;
      height: 100px;
  }

  ::-webkit-scrollbar-track {
      background: @foreground-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: @foreground-color;
    scrollbar-track-color: @background-color;
  }
}

.flex-center-justify {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-select {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

/*************************************/
/* ------------ TRANSISIONS ------------ */
/*************************************/

@easeOutCubic: cubic-bezier(.215,.61,.355,1);
@easeInOutCubic: cubic-bezier(.645,.045,.355,1);
@easeInCirc: cubic-bezier(.6,.04,.98,.335);
@easeOutCirc: cubic-bezier(.075,.82,.165,1);
@easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
@easeInExpo: cubic-bezier(.95,.05,.795,.035);
@easeOutExpo: cubic-bezier(.19,1,.22,1);
@easeInOutExpo: cubic-bezier(1,0,0,1);
@easeInQuad: cubic-bezier(.55,.085,.68,.53);
@easeOutQuad: cubic-bezier(.25,.46,.45,.94);
@easeInOutQuad: cubic-bezier(.455,.03,.515,.955);
@easeInQuart: cubic-bezier(.895,.03,.685,.22);
@easeOutQuart: cubic-bezier(.165,.84,.44,1);
@easeInOutQuart: cubic-bezier(.77,0,.175,1);
@easeInQuint: cubic-bezier(.755,.05,.855,.06);
@easeOutQuint: cubic-bezier(.23,1,.32,1);
@easeInOutQuint: cubic-bezier(.86,0,.07,1);
@easeInSine: cubic-bezier(.47,0,.745,.715);
@easeOutSine: cubic-bezier(.39,.575,.565,1);
@easeInOutSine: cubic-bezier(.445,.05,.55,.95);
@easeInBack: cubic-bezier(.6,-.28,.735,.045);
@easeOutBack: cubic-bezier(.175, .885,.32,1.275);
@easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);

/*************************************/
/* ------------ MEDIA QUERIES ------------ */
/*************************************/
@laptop: ~"only screen and (min-width: 1024px) and (min-height:700px)";
@desktop: ~"only screen and (min-width: 1500px) and (min-height:1080px)";
@mobileOverride: ~"only screen and (min-width: 480px)";

@325: ~"only screen and (min-width: 325px)";
@375: ~"only screen and (min-width: 375px)";
@480: ~"only screen and (max-device-width: 480px)";
@500: ~"only screen and (min-width: 500px)";
@768-470: ~"only screen and (min-width: 768px) and (min-height:470px)";
@768x2: ~"only screen and (min-width: 768px) and (min-height:768px)";
@768: ~"only screen and (min-width: 768px)";
@768tall: ~"only screen and (min-width: 768px) and (min-height:1024px)";
@768h: ~"only screen and (min-height: 768px)";
@1024: ~"only screen and (min-width: 1024px)";
@900: ~"only screen and (min-width: 900px)";
@1024-620: ~"only screen and (min-width: 1024px) and (min-height:620px)";
@1200: ~"only screen and (min-width: 1200px)";
@1200-768: ~"only screen and (min-width: 1200px) and (min-height:768px)";
@1280: ~"only screen and (min-width: 1280px)";
@1366: ~"only screen and (min-width: 1366px)";
@1600: ~"only screen and (min-width: 1600px)";
@2000: ~"only screen and (min-width: 2000px)";
