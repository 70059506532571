
@infoWidth:600px;
@inputWidth:600px;
@inputHeight:300px;

#payment-page{
	.layer;

	#payment{
		text-align: center;
		position: absolute;
		width:100%;
		left:0;
		top:0;
		padding-top:20px;

		&.mobile{
			width:100%;
			height:100%;

		}

		iframe{
			border:2px solid #666;
		}
	}

	.below-video{
		margin-top:25px;
		width:960px;
		display: inline-block;

        p{
            margin:0 auto;
            max-width:650px;
            font-family:Arial, helvetica, sans-serif;
            font-weight:normal;
            font-size:17px;
            color:#a7a5a1;
            text-align:justify;

            .strong{
                color:white;
            }
        }
	}

	.btn-wrapper{
        margin-top:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }

	.banner-wrapper{
        margin-top:10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
	.above-banner{
        margin-top:25px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
	.below-banner{
        margin-top:10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
}