#cam-page{
	.layer;

	@camWidth:640px;
	@camHeight:360px;
	@camScale:1.2;
	@uiWidth:700px;
	@uiHeight:550px;

	#cam-background{
		.layer;
	}

	#cam-ui{
		position: absolute;
		width:@uiWidth;
		height:@uiHeight;	
		top:(@appHeight - @uiHeight) / 2;
		left:(@appWidth - @uiWidth) / 2;
		text-align: center;

		.btn-plus{
			margin:160px;
			transform:scale(0.75);
			transform-origin:center center;
		}

		.cta{
			position:absolute;
			bottom:-25px;
			width:100%;
			text-align: center;

			span {
				transition: all 1s ease;
			}
		}

		.error{
			position:absolute;
			bottom:-75px;
			width:100%;
			color:@errorRed;
			text-align: center;
		}
	}

	#stream-wrapper{
		border:1px solid white;
		transform-origin:center center;
		transform:scale(@camScale);
		position: absolute;
		width:@camWidth;
		height:@camHeight;	
		top:(@appHeight - @camHeight) / 2;
		left:(@appWidth - @camWidth) / 2;
		overflow: hidden;
		background:black;

		&.loaded{
			box-shadow: 0px 0px 100px 10px rgba(50, 150, 190, 0.7);
		}

		#stream-player{
			.layer;
			transform:scale(-1,1);
		}

		#overlay-canvas{
			.layer;
			transform:scale(-1,1);
		}

		&.show{
			display: block;
		}

		.icon{
			position:absolute;
			top:10px;
			right:10px;

			&.green{
				color:green;
			}

			&.red{
				color:red;
			}

			&.purple{
				color:purple;
				transform-origin: center center;
				animation: rotating 2s linear infinite;
			}
		}
	}
}

.device-selector{
	margin-top:50px;

	.device{
		cursor: pointer;
		width:400px;
		white-space: nowrap;
		overflow:hidden;
		text-align: center;
		background:rgba(255,255,255,0.05);
		border-radius:3px;
		padding:8px;
		margin:10px auto 10px auto;
		display: block;

		&:hover{
			color:white;
			background:rgba(255,255,255,0.1);
		}
	}
}

.cam-loader{
	.layer;
	background:black;
	clip-path: inset(2px 2px 2px 2px);
	video{
		transform-origin:center center;
		transform: scale(1.3);
		transition-timing-function: ease-in-out;
		animation: pulse 1.5s infinite linear;
		width:300px;
		height:300px;
		top:50%;
		left:50%;
		margin-top:-150px;
		margin-left:-150px;
		position: absolute;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
  }