
@barWidth:420px;
@videoWidth:600px;
@videoHeight:300px;

.upload-progress{
	
	box-sizing: border-box;
	margin-top:150px;
	transform-origin:center center;
	transform:scale(0.5);
	width:@barWidth;
	height:@videoHeight;

	.upload-vid{
		width:@videoWidth;
		height:@videoHeight;
	}

	.bar{

		width:@barWidth;
		height:@videoHeight;
		overflow: hidden;
		position: relative;

		.bar-bg{
			.layer;
			overflow: hidden;
			filter:saturate(0%);
		}

		.bar-progress{
			.layer;
			overflow: hidden;
		}

		video{
			position:absolute;
			top:0px;
			left:(@barWidth - @videoWidth ) /2;
			width:@videoWidth;
			height:@videoHeight;
		}
	}
}

@keyframes barberpole {
   from { background-position: 0 0; }
   to   { background-position: 40px 20px; }
}
	