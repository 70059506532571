

#info-page-original{
	.layer;
	// background:url('../../img/lollipopbg.jpg') center center/cover no-repeat;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    padding-bottom: 175px;
    box-sizing: border-box;

	.btn-purchase{
		width:150px;
		height:21px;
		background:url('../../img/btn_purchase.png') center center/100% 100% no-repeat;
		box-sizing: border-box;
		margin-top:20px;
		.KnockoutJr;
		color:white;
		line-height:21px;
		// filter:blur(0.5px);
		text-align:center;
		cursor:pointer;
	}

	.btn-connect{
		width:169px;
		height:21px;
		background:url('../../img/connectwithfacebook.png') center center/100% 100% no-repeat;
		box-sizing: border-box;
		margin-top:20px;
		.KnockoutJr;
		color:white;
		padding-left: 23px;
		line-height:21px;
		text-align:center;
		cursor:pointer;
	}

	.lollipop-wrapper{
		position:relative;
		width:100%;
		height:304px;

		.dare{
			position:absolute;
			top: 50%;
			left:0px;
			width:100%;
			color:@originalBlue;
			opacity:0.40;
			.Knockout;
			font-size:40px;
		}

		.lollipop{
			position:absolute;
			top:0px;
			left: 50%;
			margin-left:-58px;
			width:116px;
			height:304px;
		}

		@media only screen and (min-height: 750px){
			height:460px;
			.dare{
				font-size:76px;
			}

			.lollipop{
				width:175px;
				height:460px;
				margin-left:-88px;
			}
		}
	}

	.invalid{
		.layer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		h2{
			font-size:24px;
			.Knockout;
			color:@originalBlue;
			opacity:0.40;
			max-width:600px;
		}
		p{
			.KnockoutJr;
			color:@originalBlue;
			opacity:0.40;
			font-size:12px;
			max-width:800px;	

			a{
				color:@originalBlue;
			}
		}
	}

	.footer{
		position:absolute;
		left:0px;
		width:100%;
		bottom:0px;
		padding-left:20px;
		padding-right:20px;
		padding-bottom:20px;
		box-sizing: border-box;
		text-align:center;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-transform:uppercase;
		.KnockoutJr;

		.headphones{
			margin-bottom:10px;
		}

		p{
			color:@originalBlue;
			font-size:8px;
			opacity:0.40;
			max-width:800px;
		}

		a{
			color:@originalBlue;
		}

		@media @laptop{

			.headphones{
				margin-bottom:20px;
			}

			p{
				font-size:12px;
			}
		}
	}
}