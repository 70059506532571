#video-page{

	@videoWidth: @appWidth - @chatWidth;

	.video-loader{
		.layer;
		display: flex;
		align-items: center;
		justify-content: center;

		.loading-ui{
			top:50%;
			margin: auto;			
			video{
				transform: scale(1.3);
				transition-timing-function: linear;
				animation: pulse 1.5s infinite;
			}

			h2 {
				width: 100%;
				left:0;
				position:absolute;
			}
		}
	}
	
	#video-wrapper{

		border:2px solid #0e0f0f;
		box-sizing:border-box;
		position:absolute;
		top:0px;
		left:0px;
		width:@videoWidth;
		height:@chatHeight;
		top:@chatTop;

		&.no-chat{
			width:@appWidth;
			height:@appHeight;
			top:0px;
			left:0px;
		}

		&.hidden{
			opacity: 0;
		}

		#base-player{
			.layer;
		}

		#stream-wrapper{
			position: absolute;
			width:@camWidth;
			height:@camHeight;
			transform-origin:bottom left;
			transform:scale(1.194);
			bottom:0px;
			left:0px;
			opacity: 0;

			&.mobile {
				width: 49.6%;
				height: 49.6%;
				transform:scale(1);
			}

			&.offset {				
				opacity: 1;
				left:50%;
			};

			&.show{
				opacity: 1;
			};

			#stream-player{
				.layer;
				transform:scale(-1,1);
			}

			#dynamic-player{
				.layer;
				transform:scale(-1,1);
			}

			#canvas-player{
				.layer;
				background:black;
    			box-sizing: border-box;
    			transform:scale(-1,1);
			}
		}

		.user-label{
			position:absolute;
			bottom:0px;
			left:0px;
			min-width:130px;
			height:35px;
			line-height:35px;
			padding-left:10px;
			padding-right:10px;
			box-sizing: border-box;
			color:#d1f1f5;
			.ProximaNovaRegular;
			background: rgba(0,0,0,0.5);
			opacity: 0;

			&.show{
				opacity: 1;
			}
			
			&.tl, &.tl2{
				left:0%;
				bottom:50%;
			}

			&.tr, &.tr2{
				left:50%;
				bottom:50%;
			}

			&.bl, &.bl2{
				left:0%;
				bottom:0%;
			}

			&.br, &.br2{
				left:50%;	
				bottom:0%;
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.70);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	50% {
		transform: scale(1.3);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.70);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}